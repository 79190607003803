<template>
  <div>
    <div
      class="gavc-field"
      v-bind:class="{ 'is-error': $v.cedantRepreneurUserUsername.$dirty ? $v.cedantRepreneurUserUsername.$error : null  }">
      <label class="gavc-label" for="registration-cedant-repreneur-cedantRepreneurUserUsername-group">
        Email :
        <span class="gavc-required"> * </span>
        <p class="gavc-legende"> Il s'agira de l'identifiant du repreneur pour se connecter. Un mail lui sera envoyé. </p>
      </label>
      <input
        class="gavc-input"
        id="registration-cedant-repreneur-cedantRepreneurUserUsername-group"
        maxlength="150"
        @input="$v.cedantRepreneurUserUsername.$touch()"
        @change="findUserByUsername"
        type="email"
        placeholder="jon@donym.fr"
        v-model="cedantRepreneurUserUsername"
      >
      <p v-if="isExistingRepreneurUsername" class="mt-1 gavc-legende gavc-text-rose">
        Un utilisateur avec cet email existe déjà, veuillez choisir un autre email.
      </p>
      <p v-if="isExistingRepreneurUsername === false" class="mt-1 gavc-legende text-success">
        Cet identifiant est disponible
      </p>
      <p v-if="$v.cedantRepreneurUserUsername.$dirty ? $v.cedantRepreneurUserUsername.$error : null " class="gavc-p gavc-text-rose">
        <i class="gavc-icon gavc-icon-cross" /> Veuillez renseigner l'email du repreneur
      </p>
    </div>


    <div class="row mt-3">
      <div class="col-12 col-md-6">
        <div
          class="gavc-field"
          v-bind:class="{ 'is-error': $v.cedantRepreneurUserFirstName.$dirty ? $v.cedantRepreneurUserFirstName.$error : null  }">
          <label class="gavc-label" for="registration-cedant-repreneur-cedantRepreneurUserFirstName-group">
            Prénom
            <span class="gavc-required"> * </span>
          </label>
          <input
            class="gavc-input"
            id="registration-cedant-repreneur-cedantRepreneurUserFirstName-group"
            maxlength="128"
            @input="$v.cedantRepreneurUserFirstName.$touch()"
            type="text"
            v-model="cedantRepreneurUserFirstName"
          >
        </div>
        <p v-if="$v.cedantRepreneurUserFirstName.$dirty ? $v.cedantRepreneurUserFirstName.$error : null " class="gavc-p gavc-text-rose">
          <i class="gavc-icon gavc-icon-cross" /> Le prénom du repreneur doit comporter entre 2 et 128 caractères
        </p>
      </div>
      <div class="col-12 col-md-6">
        <div
          class="gavc-field"
          v-bind:class="{ 'is-error': $v.cedantRepreneurUserLastName.$dirty ? $v.cedantRepreneurUserLastName.$error : null  }">
          <label class="gavc-label" for="registration-cedant-repreneur-cedantRepreneurUserLastName-group">
            Nom
            <span class="gavc-required"> * </span>
          </label>
          <input
            class="gavc-input"
            id="registration-cedant-repreneur-cedantRepreneurUserLastName-group"
            maxlength="128"
            @input="$v.cedantRepreneurUserLastName.$touch()"
            type="text"
            v-model="cedantRepreneurUserLastName"
          >
        </div>
        <p v-if="$v.cedantRepreneurUserLastName.$dirty ? $v.cedantRepreneurUserLastName.$error : null " class="gavc-p gavc-text-rose">
          <i class="gavc-icon gavc-icon-cross" /> Le nom du repreneur doit comporter entre 2 et 128 caractères
        </p>
      </div>
    </div>


    <div class="gavc-field">
      <div class="gavc-radio-checkbox">
        <label class="gavc-label" for="registration-cedant-repreneur-cedantRepreneurIsPersonnePhysique">
            Le repreneur reprend votre entreprise
            <span class="gavc-required"> * </span>
        </label>
        <div class="gavc-radio">
          <input :value="true" type="radio" id="registration-cedant-repreneur-cedantRepreneurIsPersonnePhysique-input-1"
                 name="registration-cedant-repreneur-cedantRepreneurIsPersonnePhysique-input-1" v-model="cedantRepreneurIsPersonnePhysique">
          <label class="gavc-label" for="registration-cedant-repreneur-cedantRepreneurIsPersonnePhysique-input-1">
            Directement (personne physique)
            <i class="gavc-icon gavc-icon-check" ></i>
          </label>
          <br>
          <input :value="false" type="radio" id="registration-cedant-repreneur-cedantRepreneurIsPersonnePhysique-input-2"
                 name="registration-cedant-repreneur-cedantRepreneurIsPersonnePhysique-input-2" v-model="cedantRepreneurIsPersonnePhysique">
          <label class="gavc-label" for="registration-cedant-repreneur-cedantRepreneurIsPersonnePhysique-input-2">
            Par le biais d'une société
            <i class="gavc-icon gavc-icon-check" ></i>
          </label>
          <p v-if="$v.cedantRepreneurIsPersonnePhysique.$dirty ? $v.cedantRepreneurIsPersonnePhysique.$error : null " class="gavc-p gavc-text-rose">
            <i class="gavc-icon gavc-icon-cross" /> Veuillez renseigner cette information
          </p>
        </div>

      </div>
    </div>
    <div v-if="cedantRepreneurIsPersonnePhysique === 'false' || cedantRepreneurIsPersonnePhysique === false">

      <hr>

      <h3 class="gavc-h3">Son entreprise</h3>

      <div
        class="gavc-field"
        v-bind:class="{ 'is-error': $v.cedantRepreneurCompanyName.$dirty ? $v.cedantRepreneurCompanyName.$error : null  }">
        <label class="gavc-label" for="registration-cedant-repreneur-company-cedantRepreneurCompanyName-group">
            La raison sociale de son entreprise est
            <span class="gavc-required"> * </span>
            <i
              class="gavc-text-martinique gavc-icon gavc-icon-bulb"
              v-b-tooltip.hover.right="{ variant: 'light' }"
              title="La raison sociale correspond au nom officiel que votre repreneur a déclaré lors de la création de son entreprise.
                     Elle figure sur son extrait d’immatriculation et dans les statuts de sa société."
            >
            </i>
        </label>
        <input
          class="gavc-input"
          id="registration-cedant-repreneur-company-cedantRepreneurCompanyName-group"
          maxlength="250"
          placeholder="Raison sociale"
          @input="$v.cedantRepreneurCompanyName.$touch()"
          type="text"
          v-model="cedantRepreneurCompanyName"
        >
        <p v-if="$v.cedantRepreneurCompanyName.$dirty ? $v.cedantRepreneurCompanyName.$error : null " class="gavc-p gavc-text-rose">
          <i class="gavc-icon gavc-icon-cross" /> La raison sociale doit comporter entre 2 et 250 caractères
        </p>
      </div>
      <div
         class="gavc-field"
         v-bind:class="{ 'is-error': $v.cedantRepreneurCompanySiren.$dirty ? $v.cedantRepreneurCompanySiren.$error : null  }">
        <label class="gavc-label" for="registration-cedant-repreneur-company-cedantRepreneurCompanySiren-input">
            Son SIREN est le
            <span class="gavc-required"> * </span>
            <i
              class="gavc-text-martinique gavc-icon gavc-icon-bulb"
              v-b-tooltip.hover.right="{ variant: 'light' }"
              title="Le SIREN est le numéro d’identification unique de l’entreprise du repreneur identifié qui a été délivré par l’Insee
                     lors de son immatriculation au registre national des entreprises. Si vous le cherchez, il apparaît sur toutes les factures et devis émis par son entreprise.
                      Vous pouvez également vous connecter sur le site de l’Insee ou Societe.com pour le retrouver."
            >
            </i>
        </label>
        <div class="gavc-field">
          <input
            class="gavc-input"
            maxlength="11"
            @input="$v.cedantRepreneurCompanySiren.$touch()"
            id="registration-cedant-repreneur-company-cedantRepreneurCompanySiren-input"
            placeholder="XXX XXX XXX"
            v-mask="'### ### ###'"
            v-model="maskedSiren"
          >
          <p v-if="$v.cedantRepreneurCompanySiren.$dirty ? $v.cedantRepreneurCompanySiren.$error : null " class="gavc-p gavc-text-rose">
            <i class="gavc-icon gavc-icon-cross" /> Le SIREN doit comporter 9 caractères
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email, required, requiredIf, minLength, maxLength, numeric } from 'vuelidate/lib/validators'
import { createHelpers } from 'vuex-map-fields';

import { APIDisconnectedService } from '@/api/APIDisconnected'
import uxMixins from '@/mixins/uxMixins'

const { mapFields } = createHelpers({
  mutationType: 'updateFieldWithLocalStorage',
});

const apiDisconnectedService = new APIDisconnectedService()

export default {
  name: 'repreneurInformation',
  mixins: [
    uxMixins,
    validationMixin
  ],
  data: function () {
    return {
      isExistingRepreneurUsername: null,

      maskedSiren: '',
    }
  },
  computed: {
    ...mapFields([
      'cedant.repreneur.cedantRepreneurIsPersonnePhysique',
      'cedant.repreneur.company.cedantRepreneurCompanyName', 'cedant.repreneur.company.cedantRepreneurCompanySiren',
      'cedant.repreneur.user.cedantRepreneurUserFirstName', 'cedant.repreneur.user.cedantRepreneurUserLastName',
      'cedant.repreneur.user.cedantRepreneurUserUsername'
    ]),
  },
  watch: {
    maskedSiren(value) {
      this.cedantRepreneurCompanySiren = value.replace(/ /g,'')
    },
    "$v.$invalid": function() {
      this.$emit('invalidInformationChanged', this.$v.$invalid)
    },

    isExistingRepreneurUsername (value) {
      this.$emit('isExistingRepreneurUsernameChanged', value)
    }
  },
  mounted () {
    this.scrollToTop()
    this.maskedSiren = this.cedantRepreneurCompanySiren
    if (!this.$v.cedantRepreneurUserUsername.$invalid) {
      this.findUserByUsername()
    }
    this.$emit('invalidInformationChanged', this.$v.$invalid)
    this.$emit('isExistingRepreneurUsernameChanged', this.isExistingRepreneurUsername)
  },
  validations: {
    cedantRepreneurIsPersonnePhysique: {
      required,
    },
    cedantRepreneurUserFirstName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(128)
    },
    cedantRepreneurUserLastName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(250)
    },
    cedantRepreneurCompanyName: {
      required: requiredIf(function() {
        return this.cedantRepreneurIsPersonnePhysique === false
      }),
      minLength: minLength(2),
      maxLength: maxLength(250)
    },
    cedantRepreneurCompanySiren: {
      required: requiredIf(function() {
        return this.cedantRepreneurIsPersonnePhysique === false
      }),
      minLength: minLength(9),
      maxLength: maxLength(9),
      numeric
    },
    cedantRepreneurUserUsername: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required,
      email
    }
  },
  methods: {
    findUserByUsername () {
      if (this.$v.cedantRepreneurUserUsername.$invalid) {
        return
      }
      apiDisconnectedService.findUserByUsername(this.cedantRepreneurUserUsername)
      .then((result) => {
        this.isExistingRepreneurUsername = result.data.isUser
      })
      .catch((error) => {
        throw new Error('repreneurRegistrationStep2 findUserByUsername API Error : ' + String(error))
      })
    }
  }
}

</script>
